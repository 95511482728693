<style lang="scss" scoped>
  .hb-form-input {
    width: 100%;
    max-width: 480px;

    &.disabled {
      label {
        opacity: 0.4;
      }
    }
  }

  /* BASE */
  .v-text-field {
    :deep(.v-field__input) {
      color: $brandBlack;
      min-height: 3.5rem;
      font-family: "Circular Std Medium";
      font-size: 1.25rem;
      @media only screen and (min-width: 960px) {
        font-size: 1.5rem;
      }
      @media only screen and (min-width: 960px) {
        min-height: 4.5rem;
      }

      &::placeholder {
        color: $brandDarkPurple;
      }
    }

    :deep(.v-field) {
      background-color: #fff;
      border-radius: 0.5rem;
    }

    :deep(.v-field__overlay) {
      opacity: 0 !important;
    }

    :deep(.v-field-label) {
      color: $brandDarkPurple;
      opacity: 0.8;
      font-family: "Circular Std Bold";
      font-size: 1.25rem;
      @media only screen and (min-width: 960px) {
        font-size: 1.5rem;
        top: 1.25rem;
      }
    }

    :deep(.v-field-label--floating) {
      /*
       * We start with a larger font size label, but want to shrink to the default font size
       * floating label. In order the the animation to work, we have to explicitly set the
       * --v-field-label-scale variable. This gets us the right target size. However, the animation
       * assumes that the scale is 0.75 (which is weird since, isn't it a variable?). This truncates
       * the label when animating. So we have to set a larger box width for the floating label.
       */
      font-size: 0.75rem;
      --v-field-label-scale: 0.5; /* 0.75 / 1.5 */
      width: 100%;
    }

    :deep(.v-icon) {
      color: $brandDarkPurple;
      font-size: 1.75rem;
      @media only screen and (min-width: 960px) {
        top: 0.25rem;
      }
    }

    :deep(.v-field__outline) {
      opacity: 1;
      transition: all 150ms ease;
      border: 1px solid rgb($brandDarkGray, 0.4);
      border-radius: 0.5rem !important;

      &:after, &:before {
        border-width: 0;
      }
    }

    :deep(.v-input__details) {
      padding-left: 0;

      .v-messages {
        opacity: 1;
      }

      .v-messages__message {
        /* Same as <small> */
        color: $brandDarkGray;
        font-family: "Circular Std";
        font-size: 0.75rem;
        line-height: 1.6;
      }
    }
  }

  /* HOVER */
  .v-text-field:hover {
    :deep(.v-field__outline) {
      border: 1px solid $brandDarkGray;
    }
  }

  /* FOCUSED */
  .v-text-field :deep(.v-field--focused) {
    .v-field__outline {
      border: 2px solid $brandPurple;
    }
  }

  /* DISABLED */
  .v-text-field :deep(.v-field--disabled) {
    opacity: 0.4;
  }

  /* ERROR */
  .v-text-field :deep(.v-field--error) {
    input {
      &::placeholder {
        color: $brandDarkRed;
      }
    }

    .v-field__outline{
      border: 2px solid $brandDarkRed;
    }
  }

  .v-text-field.v-input--error :deep(.v-messages__message) {
    color: $brandDarkRed;
  }
</style>

<template>
  <div class="hb-form-input" :class="{ disabled }">
    <v-text-field
      ref="el"
      hide-details="auto"
      :disabled="disabled"
      :label="label"
      v-bind="$attrs"
    />
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
};

</script>

<script setup>

defineProps({
  label: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const el = ref();

function focus () {
  setTimeout(() => {
    el.value.$el.querySelector('input').focus();
  }, 250);
}

defineExpose({
  focus,
});

</script>
